import React, { Component } from 'react'
import PropTypes from 'prop-types'
import nameToId from 'src/lib/nameToId'
import Select from 'react-select'

import { selectStyles, theme } from 'styles/ReactSelect'

export default class DynamicSelect extends Component {
  constructor (props) {
    super(props)
    this.onChange = this.onChange.bind(this)
  }

  onChange (change) {
    const { name } = this.props
    const value = change ? (this.props.multi ? change.map(c => c.value) : change.value) : null
    const payload = { target: { name, value } }
    this.props.onSelect(payload)
  }

  render() {
    const {
      clearable,
      currentValue,
      multi,
      name,
      noResultsText,
      placeholderText,
      values,
    } = this.props

    const valuesArray = multi ? currentValue : [currentValue]
    const selectedOptions = values.filter(option => valuesArray.includes(option.value))

    return (
      <div className="dynamic-select" data-label={this.props.placeholderText}>
        <Select
          className='Select'
          classNamePrefix="Select"
          isClearable={clearable}
          id={nameToId(name)}
          isMulti={multi}
          name={name}
          noOptionsMessage={() => noResultsText}
          onChange={this.onChange}
          options={values}
          placeholder={placeholderText}
          styles={selectStyles}
          theme={theme}
          value={selectedOptions}
        />
      </div>
    );
  }
}

DynamicSelect.defaultProps = {
  multi: false
}

DynamicSelect.propTypes = {
  clearable: PropTypes.bool.isRequired,
  currentValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  multi: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  noResultsText: PropTypes.string.isRequired,
  onSelect: PropTypes.func.isRequired,
  placeholderText: PropTypes.string.isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  )
};
