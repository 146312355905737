const COLOURS = {
  BODY_BG: '#1a1a1a',
  BODY_FONT: '#cacaca',
  NAV_BTN_HOVER: '#656565',
  SUBDUED: '#2f2f2f',
  UTIL: '#3e3e3e',
  WHITE: '#fff'
}

export const selectStyles = {
  clearIndicator: styles => ({...styles}),
  container: styles => ({...styles}),
  control: (styles) => ({
    ...styles,
    '&:hover': {
      borderWidth: '0 0 1px',
      borderColor: COLOURS.WHITE,
    },
    '&:focus': {
      borderWidth: '0 0 1px',
      borderColor: COLOURS.WHITE,
    },
    borderWidth: '0 0 1px',
    borderColor: COLOURS.WHITE,
    backgroundColor: COLOURS.BODY_BG,
    boxShadow: 'none',
  }),
  dropdownIndicator: styles => ({...styles, cursor: 'pointer'}),
  group: styles => ({...styles}),
  groupHeading: styles => ({...styles}),
  indicatorsContainer: styles => ({...styles}),
  indicatorSeparator: styles => ({...styles, opacity: 0}),
  input: styles => ({...styles, color: COLOURS.BODY_FONT, padding: 0, margin: 0}),
  loadingIndicator: styles => ({...styles}),
  loadingMessage: styles => ({...styles}),
  menu: styles => ({...styles, backgroundColor: COLOURS.SUBDUED}),
  menuList: styles => ({...styles}),
  menuPortal: styles => ({...styles}),
  multiValue: styles => ({...styles, backgroundColor: COLOURS.SUBDUED}),
  multiValueLabel: styles => ({...styles, color: COLOURS.BODY_FONT, padding: '6px 4px 4px 6px'}),
  multiValueRemove: (styles) => {
    return {
      ...styles,
      color: COLOURS.BODY_FONT,
      '&:hover': {
        color: COLOURS.BODY_FONT,
        backgroundColor: COLOURS.NAV_BTN_HOVER,
      },
      '&:focus': {
        color: COLOURS.BODY_FONT,
        backgroundColor: COLOURS.NAV_BTN_HOVER,
      },
    }
  },
  noOptionsMessage: styles => ({...styles, backgroundColor: COLOURS.SUBDUED}),
  option: (styles, { isFocused }) => {
    return {
      ...styles,
      cursor: 'pointer',
      backgroundColor: isFocused ? COLOURS.UTIL : COLOURS.SUBDUED,
    }
  },
  placeholder: styles => ({...styles}),
  singleValue: styles => ({...styles, color: COLOURS.BODY_FONT}),
  valueContainer: styles => ({...styles, padding: '0px 6px'}),
}

export const theme = (theme) => ({
  ...theme,
  borderRadius: 0,
  colors: {
    ...theme.colors,
    primary: 'transparent',
    primary75: 'transparent',
    primary50: 'transparent',
    primary25: 'transparent',
  },
})
