import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DynamicSelect from './DynamicSelect'

export default class DynamicSelectWithState extends Component {
  constructor(props) {
    super(props);
    this.state = { currentValue: props.currentValue };
    this.setCurrentValue = this.setCurrentValue.bind(this)
  }

  render() {
    const {
      clearable,
      multi,
      name,
      noResultsText,
      placeholderText,
      values,
    } = this.props

    return (
      <div>
        <DynamicSelect
          clearable={clearable}
          currentValue={this.state.currentValue}
          multi={multi}
          name={name}
          noResultsText={noResultsText}
          onSelect={this.setCurrentValue}
          placeholderText={placeholderText}
          values={values}
        />
      </div>
    );
  }

  setCurrentValue(event) {
    this.setState({ currentValue: event.target.value });
  }
}

DynamicSelectWithState.propTypes = {
  clearable: PropTypes.bool.isRequired,
  currentValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  multi: PropTypes.bool,
  name: PropTypes.string.isRequired,
  noResultsText: PropTypes.string,
  placeholderText: PropTypes.string,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  )
};
