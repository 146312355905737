import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'

export default class HomePage extends PureComponent {
  static propTypes = {
    urlToLogin: PropTypes.string.isRequired,
    useSaml: PropTypes.bool.isRequired,
    ssoAuthorizePaths: PropTypes.array.isRequired,
    authenticityToken: PropTypes.string.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      currentTab: "sso",
    }
  }

  getStatusClass = (tab) =>{
    return this.state.currentTab == tab ? "active" : ""
  }

  handleTabs = (tabSelected) =>{
    this.setState({currentTab: tabSelected })
  }

  render() {
    const {
      ssoAuthorizePaths,
      urlToLogin,
      authenticityToken,
      useSaml,
    } = this.props

    var linksToSso = []
    ssoAuthorizePaths.forEach((path,index) => {
      linksToSso.push(<a key={index} className="btn submit-btn" href={path}>Log in</a>)
    });

    const sectionSso = <section>
      <div className="grid-form">
        {linksToSso}
      </div>
    </section>

    const sectionFormLogin = <form action={urlToLogin} acceptCharset="UTF-8" method="post">
    <input name="utf8" type="hidden" value="✓"></input>
    <input type="hidden" name="authenticity_token" value={authenticityToken}></input>
    <section>
      <div className="grid-form">
        <input type="email" name="user[email]" id="user_email" placeholder="you@example.com"></input>
      </div>
    </section>
    <section>
      <div className="grid-form">
        <input type="password" name="user[password]" id="user_password" placeholder="Password"></input>
      </div>
    </section>
    <footer>
      <div className="grid-footer">
        <input type="submit" name="commit" value="Log in" className="btn submit-btn right"></input>
      </div>
    </footer>
  </form>

    return (
      <Fragment>
        {useSaml ?
          <Fragment>
            <nav className="tab-container">
              <ul className="tabs tab-container-inner">
                <li onClick={() => {this.handleTabs("sso")}} className={"pointer tab-title " + this.getStatusClass("sso")}>
                  <a href="#">Company Login</a>
                </li>
                <li onClick={() => {this.handleTabs("login")}} className={"pointer tab-title " + this.getStatusClass("login")}>
                  <a href="#">Basic Login</a>
                </li>
              </ul>
            </nav>
            {this.state.currentTab == "sso" ? sectionSso : sectionFormLogin}
          </Fragment>
        : sectionFormLogin
        }
      </Fragment>
    )
  }
}
